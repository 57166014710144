import { bbox } from '@turf/bbox';
import { borderColor, minValueColor, maxValueColor } from './utils';

const sourceName = 'regions-data';
const fillLayerName = 'regions-fill';
const outlineLayerName = 'regions-outline';

export default class RegionsLayer {
  constructor(map, data) {
    this.map = map;
    this.data = data;

    // Pour savoir si et sur quelle région on a mis un popup après le passage du user
    this.popupedRegion = null;
    // Pour savoir sur quelle région on doit zoomer
    this.regionFocusedOn = null;

    this.map.addSource(sourceName, {
      type: 'geojson',
      data: this.data,
    });

    this.fillLayerName = fillLayerName;
    this.outlineLayerName = outlineLayerName;
  }

  add() {
    this.addFillLayer();
    this.addOutlineLayer();
  }

  changeColoredPropertyTo(coloredProperty) {
    let expression;
    if (coloredProperty === 'providersCount') {
      expression = ['interpolate',
        ['exponential', 0.9],
        ['get', 'providersCount'], // Utilise la valeur fusionnée
        0, minValueColor,
        this.providersCountMaxValue, maxValueColor];
    } else {
      expression = [
        'interpolate',
        ['exponential', 1],
        ['get', 'totalAmount'], // Utilise la valeur fusionnée
        0, minValueColor,
        this.amountMaxValue, maxValueColor];
    }

    this.map.setPaintProperty(
      fillLayerName,
      'fill-color',
      expression,
    );
  }

  addFillLayer() {
    this.map.addLayer({
      id: fillLayerName,
      type: 'fill',
      source: sourceName,
      paint: {
        'fill-color': [
          'interpolate',
          ['exponential', 0.9],
          ['get', 'providersCount'], // Utilise la valeur fusionnée
          0, minValueColor,
          this.providersCountMaxValue, maxValueColor,
        ],
      },
    });
  }

  addOutlineLayer() {
    this.map.addLayer({
      id: outlineLayerName,
      type: 'line',
      source: sourceName,
      paint: {
        'line-color': borderColor,
        'line-width': 1,
      },
    });
  }

  on(eventName, callback) {
    this.map.on(eventName, fillLayerName, (event) => {
      callback(event);
    });
  }

  get providersCountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.providersCount));
  }

  get amountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.totalAmount));
  }

  get bbox() {
    return bbox(this.data);
  }

  set initialZoomLevel(value) {
    // this.map.setLayerZoomRange(fillLayerName, 0, value + 2);
    // this.map.setLayerZoomRange(outlineLayerName, 0, value + 2);
    this.map.setPaintProperty(fillLayerName, 'fill-opacity', [
      'interpolate',
      ['linear'],
      ['zoom'],
      value, 1,
      value + 3, 0,
    ]);

  //   'fill-opacity': [
  //     'interpolate',
  //     ['linear'],
  //     ['zoom'],
  //     4, 0, // Zoom 4 : totalement transparent
  //     5, 1  // Zoom 5 : totalement opaque
  // ]
  }
}
