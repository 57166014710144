import { Controller } from '@hotwired/stimulus';
import mapboxgl from 'mapbox-gl';
import bbox from '@turf/bbox';
import { getCSSColor } from '../../../utils';
import RegionsLayer from '../../../modules/geo/regions_layer';
import DptsLayer from '../../../modules/geo/dpts_layer';

// or "const mapboxgl = require('mapbox-gl');"
mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JlZ29jYXJ0byIsImEiOiJjbHhpc2dvMTExa2N6MnJyMmwxaWlzYjEyIn0.3XHk_q6xaz6W6VKBdUzHkg';

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ['map'];

  static values = {
    localBuyingArea: Object,
    providers: Object,
  };

  async initialize() {
    this.primaryColor = getCSSColor('primary');
    this.accentColor = getCSSColor('accent1');

    // Can be providersCount or amount
    this.coloredProperty = null;

    // Pour savoir sur quel département on a mis un popup
    this.popupedDepartement = null;

    // Pour savoir si on est focus sur une région ou non
    this.focusedOnRegion = null;

    this.providersData = this.providersValue;

    this.initializingMap = true;

    this.initialZoomLevel = null;
  }

  async connect() {
    this.buildMap();

    this.map.on('load', async () => {
      await this.getAndMergeRegions();

      this.handleRegionsLayer();
    });
  }

  setColoredProperty(e) {
    this.coloredProperty = e.target.value;
    // changer la couleur de remplissage du layer
    if (this.focusedOnRegion === null) {
      this.regionsLayer.changeColoredPropertyTo(this.coloredProperty);
    } else {
      this[`dptsLayer${this.focusedOnRegion}`].changeColoredPropertyTo(this.coloredProperty);
    }
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: this.mapTarget, // container ID
      style: 'mapbox://styles/mapbox/streets-v12', // style URL
      center: [2.333333, 48.866667], // starting position [lng, lat],
    });

    this.map.setPadding({
      top: 50, left: 100, bottom: 0, right: 0,
    });
  }

  handleRegionsLayer() {
    this.regionsLayer = new RegionsLayer(this.map, this.regions);
    this.regionsLayer.add();

    this.map.on('zoomend', () => {
      if (this.initializingMap === true) {
        this.initializingMap = false;
        this.initialZoomLevel = this.map.getZoom();
        this.loadDtps(); // Chargement des dpts
      } else if (this.map.getZoom() <= this.initialZoomLevel) {
        this.defocusFrom(this.focusedOnRegion);
      }
      // } else if (this.map.getZoom() >= this.initialZoomLevel + 1.5) {
      //   this.showDepartements();
      // }
    });

    // // A lieu après un drag, zoom ou pan ou après un map.fitBounds
    // this.map.on('moveend', (e) => {
    //   console.log('moveend');
    //   if (typeof this.initialZoomLevel === 'undefined') { // Si c'est le chargement initial
    //     this.initialZoomLevel = this.map.getZoom();
    //     this.loadDtps(); // Chargement des dpts
    //   }
    // });

    this.regionsLayer.on('mousemove', (e) => {
      console.log('mousemove');
      if (e.features.length > 0) {
        const feature = e.features[0];

        if (feature.properties.code !== this.popupedRegion) {
          this.map.getCanvas().style.cursor = 'pointer';
          if (this.popup != null) {
            this.popup.remove(); // Remove previous popup when changing region hovered
          }

          // // Show a popup with feature information
          this.popup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(`<h3>${feature.properties.nom}</h3><p>Nombre de prestataires: ${feature.properties.providersCount}<br /> Total dépensé: ${feature.properties.totalAmount.toLocaleString('fr-FR', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 2 })} €</p>`)
            .addTo(this.map);

          this.popupedRegion = feature.properties.code;
        }

        if (feature.properties.code !== this.focusedOnRegion) {
          console.log('mouse move', this.initialZoomLevel);
          if (this.focusedOnRegion) {
            this.defocusFrom(this.focusedOnRegion);
          }
          if (this.map.getZoom() > this.initialZoomLevel) {
            this.focusOnRegion(feature.properties.code);
          }
        }
      }
    });

    this.regionsLayer.on('mouseleave', (e) => {
      this.map.getCanvas().style.cursor = '';
      this.popup.remove();
      this.popupedRegion = null;
    });

    this.map.fitBounds(this.regionsLayer.bbox);
  }

  async getAndMergeRegions() {
    const regionsResponse = await fetch('/explore/geo/regions.json');
    this.regions = await regionsResponse.json();

    this.regions.features.forEach((feature) => {
      const currentCode = feature.properties.code;

      const matchingProviders = this.providersData
        .features
        .filter((provider) => provider.properties.region.toString() === currentCode);

      feature.properties.providersCount = matchingProviders.length;
      feature.properties.totalAmount = matchingProviders.reduce((acc, provider) => acc + parseFloat(provider.properties.amount), 0);
    });
  }

  getAndMergeDepartements(departements) {
    departements.features.forEach((feature) => {
      const currentCode = feature.properties.code;

      const matchingProviders = this.providersData
        .features
        .filter((provider) => provider.properties.departement.toString() === currentCode);

      feature.properties.providersCount = matchingProviders.length;
      feature.properties.totalAmount = matchingProviders.reduce((acc, provider) => acc + parseFloat(provider.properties.amount), 0);
    });
  }

  async loadDtps() {
    const regionCodes = this.regions.features.map((f) => f.properties.code);

    await Promise.all(regionCodes.map(async (regionCode) => {
      if (typeof this[`dptsLayer${regionCode}`] === 'undefined') {
        const departementsResponse = await fetch(`/explore/geo/departements.json?region=${regionCode}`);
        const departements = await departementsResponse.json();

        this.getAndMergeDepartements(departements);

        this[`dptsLayer${regionCode}`] = new DptsLayer(regionCode, this.map, departements, this.initialZoomLevel);
        this[`dptsLayer${regionCode}`].add();
        this[`dptsLayer${regionCode}`].on('mousemove', (event) => {
          if (event.features.length > 0) {
            const feature = event.features[0];

            if (feature.properties.code !== this.popupedDepartement) {
              this.map.getCanvas().style.cursor = 'pointer';
              if (this.popup != null) {
                this.popup.remove(); // Remove previous popup when changing region hovered
              }

              // Show a popup with feature information
              this.popup = new mapboxgl.Popup()
                .setLngLat(event.lngLat)
                .setHTML(`<h3>${feature.properties.nom}</h3><p>Nombre de prestataires: ${feature.properties.providersCount}<br /> Total dépensé: ${feature.properties.totalAmount.toLocaleString('fr-FR', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 2 })} €</p>`)
                .addTo(this.map);

              this.popupedDepartement = feature.properties.code;
            }
          }
        });
      }

      // this[`dptsLayer${regionCode}`].on('mouseleave', () => {
      //   this.map.getCanvas().style.cursor = '';
      //   this.popup.remove();
      //   this.popupedRegion = null;
      // });
    }));
  }

  focusOnRegion(regionCode) {
    this.focusedOnRegion = regionCode;
    console.log('focusing on region', regionCode, this[`dptsLayer${regionCode}`].outlineLayerName, this.initialZoomLevel);

    this.map.setPaintProperty(this.regionsLayer.outlineLayerName, 'line-width', [
      'case',
      ['all',
        ['==', ['get', 'code'], regionCode], // Comparaison du code
        ['>=', this.map.getZoom(), this.initialZoomLevel + 2],
      ],
      4, // Épaisseur du contour sur la feature survolée
      1, // Épaisseur par défaut
    ]);

    this.map.setPaintProperty(
      this[`dptsLayer${regionCode}`].fillLayerName,
      'fill-opacity',
      [
        'interpolate',
        ['linear'],
        ['zoom'],
        this.initialZoomLevel - 2, 0,
        this.initialZoomLevel + 2, 0.8,
      ],
    );

    // this.map.setPaintProperty(
    //   this[`dptsLayer${regionCode}`].outlineLayerName,
    //   'fill-opacity',
    //   [
    //     'interpolate',
    //     ['linear'],
    //     ['zoom'],
    //     this.initialZoomLevel - 2, 0,
    //     this.initialZoomLevel + 2, 0.8,
    //   ],
    // );
  }

  defocusFrom(regionCode) {
    if (typeof regionCode !== 'undefined') {
      this.map.setPaintProperty(this[`dptsLayer${regionCode}`].fillLayerName, 'fill-opacity', 0);
      this.focusedOnRegion = null;
    }
  }

  set initialZoomLevel(value) {
    if (value != null) {
      this._initialZoomLevel = value;
      this.regionsLayer.initialZoomLevel = value;
    }
  }

  get initialZoomLevel() {
    return this._initialZoomLevel;
  }
}
