import { bbox } from '@turf/bbox';

import { borderColor, minValueColor, maxValueColor } from './utils';

export default class RegionsLayer {
  constructor(regionCode, map, data, initialZoomLevel) {
    this.regionCode = regionCode;
    this.map = map;
    this.data = data;
    this.initialZoomLevel = initialZoomLevel;

    this.sourceName = `dpts-${regionCode}-data`;
    this.fillLayerName = `dpts-${regionCode}-fill`;
    this.outlineLayerName = `dpts-${regionCode}-outline`;

    this.map.addSource(this.sourceName, {
      type: 'geojson',
      data: this.data,
    });

    // console.log('dpts', this.data);
  }

  add() {
    this.addOutlineLayer();
    this.addFillLayer();
  }

  addFillLayer() {
    this.map.addLayer({
      id: this.fillLayerName,
      type: 'fill',
      source: this.sourceName,
      minzoom: this.initialZoomLevel + 2,
      paint: {
        'fill-color': [
          'interpolate',
          ['exponential', 0.9],
          ['get', 'providersCount'], // Utilise la valeur fusionnée
          0, minValueColor,
          this.providersCountMaxValue, maxValueColor,
        ],
        'fill-opacity': 0,
      },
    }, this.outlineLayerName);
  }

  addOutlineLayer() {
    this.map.addLayer({
      id: this.outlineLayerName,
      type: 'line',
      source: this.sourceName,
      minzoom: this.initialZoomLevel + 2,
      paint: {
        'line-color': borderColor,
        'line-width': 1,
      },
    }, 'regions-fill');
  }

  on(eventName, callback) {
    this.map.on(eventName, this.fillLayerName, (event) => {
      callback(event);
    });
  }

  changeColoredPropertyTo(coloredProperty) {
    let expression;
    if (coloredProperty === 'providersCount') {
      expression = ['interpolate',
        ['exponential', 0.9],
        ['get', 'providersCount'], // Utilise la valeur fusionnée
        0, minValueColor,
        this.providersCountMaxValue, maxValueColor];
    } else {
      expression = [
        'interpolate',
        ['exponential', 1],
        ['get', 'totalAmount'], // Utilise la valeur fusionnée
        0, minValueColor,
        this.amountMaxValue, maxValueColor];
    }

    this.map.setPaintProperty(
      this.fillLayerName,
      'fill-color',
      expression,
    );
  }

  get providersCountMaxValue() {
    const max = Math.max(...this.data.features.map((f) => f.properties.providersCount));
    return max === 0 ? 1 : max;
  }

  get amountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.totalAmount));
  }

  get bbox() {
    return bbox(this.data);
  }
}
